@import '../variables';
@import '../mixins';

$community-avatar-diameter: 84px;
$community-avatar-margin-right: 24px;
$avatar-total-width: $community-avatar-diameter + $community-avatar-margin-right;
$eventcall-avatar-diameter: 65px;
$eventcall-avatar-margin-right: $avatar-total-width - $eventcall-avatar-diameter;
$description-max-width: 500px;

.details-banner-wrapper {
  width: 100%;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  &.event-call {
    background-color: $background-highlight;
  }

  .details-banner {
    padding: 36px $main-horizontal-padding;

    &.modal {
      padding: 45px 50px;
    }

    .details-banner-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div:not(.buttons) {
        min-width: 784px;
        display: flex;
        align-items: center;
        @include responsive(1200px) {
          min-width: 0;
        }
      }

      .details-avatar {
        width: $community-avatar-diameter;
        height: $community-avatar-diameter;
        border-radius: 50%;
        margin-right: $community-avatar-margin-right;
        z-index: 3;
        flex: 0 0 auto;

        &.modal {
          width: 60px;
          height: 60px;
        }
      }

      .banner-date-badge {
        width: $eventcall-avatar-diameter;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-right: $eventcall-avatar-margin-right;
        padding: 11px 18px;
        background-color: $blue;
        border-radius: 2px;
        z-index: 2;

        .day {
          font-weight: 700;
          font-size: 11px;
          line-height: 1.45;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: currentColor;
        }

        .date {
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 32px;
          line-height: 1.06;
          color: currentColor;
        }
      }

      .action-avatar {
        width: $eventcall-avatar-diameter;
        height: $eventcall-avatar-diameter;
        border-radius: 50%;
        background-color: $orange-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $eventcall-avatar-margin-right;
        z-index: 2;

        & svg {
          width: 27px;
          height: 25px;

          path {
            stroke: #fff;
          }
        }
      }

      .details-banner-main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 3;
        max-width: 450px;
        margin-right: 50px;

        &.event-call-title {
          width: 100%;
          max-width: 580px;
          margin-right: 20px;

          &.event-title {
            .banner-title {
              color: $blue;
            }
            svg path {
              stroke: $text-5;
              fill: transparent;
            }
          }

          &.call-title {
            .banner-title {
              color: $orange-dark;
            }
            svg path {
              fill: $text-5;
            }
          }

          .banner-info {
            .text {
              color: $text-5;
            }
          }
        }

        .banner-title {
          color: #fff;
          width: 100%;

          &.modal {
            font-size: 24px;
            line-height: 36px;
          }

          .cohort-pill {
            vertical-align: top;
            margin-left: $grid-gap-basis;
            margin-top: floor(calc(($line-height-h1 - $pill-large-height) / 2));
          }
        }

        .banner-info {
          display: flex;
          align-items: center;
          margin-top: 5px;

          & svg {
            width: 18px;
            height: 18px;
            margin-right: 7px;

            path {
              fill: #fff;
            }
          }

          .text {
            font-weight: 700;
            color: #fff;
            margin-top: 1px;
          }
        }

        .with-buttons {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & h4 {
            font-weight: 400;

            &.modal {
              font-size: 14px;
              line-height: 15px;
            }
          }

          .buttons {
            display: flex;
          }
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        min-width: fit-content;
        z-index: 2;

        .button {
          margin-right: 16px;
        }

        > .button:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.details-link {
  -ms-grid-column-align: center;
  justify-self: center;
  grid-column: 2;
  -ms-grid-column: 2;
}

.link-container {
  padding-top: 30px;
  padding-bottom: 40px;
}

.details-description {
  display: flex;
  padding-bottom: 54px;
  justify-content: space-between;

  .description-content {
    margin-right: 60px;
    width: 100%;

    & > p {
      max-width: $description-max-width;
      margin-bottom: 24px;
    }

    .mindr-rich-content {
      max-width: $description-max-width;
      margin-bottom: 24px;
    }

    .description-title {
      margin-bottom: 8px;
      color: $text-5;
      max-width: $description-max-width;

      &.community-title {
        color: $teal-dark;
      }

      &.cohort-title {
        color: $purple;
      }
    }

    .detail {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      max-width: $description-max-width;

      svg {
        width: 14px;
        height: 14px;
        min-width: 14px;
        margin-right: 10px;

        path {
          fill: $blue;
        }
      }

      &.icon-stroke {
        svg {
          path {
            fill: none;
            stroke: $blue;
          }
        }
      }

      p {
        display: flex;
        width: calc(100% - 24px);
        flex-wrap: nowrap;
        align-items: center;
        white-space: nowrap;

        .details-link {
          display: inline-block;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .host-text {
          padding-right: 6px;
        }
      }

      &.event-cohost,
      &.call-cohost {
        .host-text {
          visibility: hidden;
          padding-left: 24px;
        }
      }

      &.action-detail {
        svg {
          path {
            fill: $orange-dark;
          }
        }

        &.icon-stroke {
          svg {
            path {
              fill: none;
              stroke: $orange-dark;
            }
          }
        }
      }
    }

    .allies {
      margin-top: 30px;
    }
  }

  .description-pictures {
    display: flex;
    justify-content: flex-end;
    width: 50%;

    &.community {
      max-width: 376px;
      justify-content: flex-start;
      flex-direction: column;

      @include responsive(1300px) {
        align-items: normal;
      }

      .slider {
        margin-left: 0;
        margin-right: 0;

        .slick-list {
          height: 376px;
        }

        .slick-dots {
          display: flex;
          justify-content: center;
          margin-top: 16px;
          list-style: none;

          button {
            position: relative;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background: none;
            border: 2px solid $text-9;
            font-size: 0;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          li {
            &:not(:last-child) {
              margin-right: 12px;
            }

            &.slick-active button:after {
              position: absolute;
              display: block;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 8px;
              top: 2px;
              left: 2px;
              background: $text-5;
            }
          }
        }
      }
    }

    @include responsive(1300px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .images-wrapper {
      position: relative;

      img {
        width: 376px;
        height: 376px;
        object-fit: cover;
      }

      .image-caption {
        position: absolute;
        bottom: (12px * -2) - 25px;
        left: 0;
        color: #ffffff;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.75);
        width: 100%;
        opacity: 0;
      }

      &:hover .image-caption {
        bottom: 0;
        opacity: 1;
        transition: bottom 500ms ease-in 0s;
      }
    }

    .description-picture {
      display: flex;
      flex-direction: column;

      img {
        width: 416px;
        height: 416px;
        margin-bottom: 8px;
        border-radius: 2px;
      }

      .caption {
        color: $text-5;
        max-width: 416px;
      }

      &.small {
        margin-right: 24px;
        @include responsive(1300px) {
          margin: 0 0 24px;
        }

        &:last-child {
          margin: 0;
        }

        img {
          width: 278px;
          height: 278px;
        }

        .caption {
          max-width: 278px;
        }
      }
    }
  }
}

.details-info-wrapper {
  display: flex;
  margin-bottom: 80px;

  .details-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 120px;

    .details-info-text {
      color: $text-3;
      font-size: 15px;
      line-height: 1.67;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.details-speakers-wrapper {
  background-color: $background-grey;
  padding: 36px 0;
}

.details-agenda-wrapper {
  background-color: $background-main;
  padding: 56px 0;
}

.details-resources-wrapper {
  width: 100%;
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:first-child {
      margin-right: 24px;
    }
  }
}

.details-resources {
  display: flex;
  flex-direction: column;

  .resources {
    &.agenda-resources {
      display: table;
    }

    background: #fff;
    padding: 20px 15px;
    border-radius: 4px;
    box-shadow: $shadow;
    height: 100%;

    &.dashboard {
      margin-bottom: 0;
    }

    .link:not(:last-child) {
      margin-bottom: 10px;
    }

    .link:last-child {
      margin-bottom: auto;
    }

    .agenda {
      display: table-row;
      vertical-align: baseline;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .agenda-time {
        display: table-cell;
        white-space: nowrap;
        color: $teal-dark;
        font-weight: 700;
        min-width: fit-content;
        padding-right: $grid-gap-basis;
      }

      .agenda-text {
        display: table-cell;
        color: $text-3;
        max-width: 342px;
        width: 99%;
      }
    }
  }
}

.details-slider-wrapper {
  padding: 40px 0;
  background-color: $background-grey;
  margin-bottom: 56px;

  .slider {
    .wrapper {
      padding: 0 12px 7px;

      .event {
        width: 100%;
      }
    }
  }
}

.team-wrapper,
.yearbook-wrapper {
  padding: 0 12px 7px;
}

.community-yearbook-slider {
  .yearbook-wrapper {
    height: 100%;
  }
}

.leader-card,
.member-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 278px;
  background: #fff;
  border-radius: 4px;
  box-shadow: $shadow;
  padding: 24px 16px;

  .info {
    display: flex;

    h3 {
      margin-top: -4px;
    }

    .subtitle {
      line-height: 19px;
      margin-top: 5px;
    }

    .aside {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 12px;
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 72px);
      flex-direction: column;
    }

    img,
    .default-user-avatar {
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 30px;
      flex: 0 0 60px;
      position: relative;
      object-fit: cover;
    }

    .pill {
      margin-top: (0 - calc($pill-small-height / 2));
      position: relative;
    }
  }

  & > .link {
    margin: 6px 0 0 72px;

    svg {
      height: 10px;
      width: 14px;
      margin-right: 3px;
    }
  }
}

.preview-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc($flash-height + $header-height + 61px);
  background-color: $background-main;
  h3 {
    margin-bottom: 33px;
  }
}

.leader-card {
  height: 144px;
  min-height: 144px;
}

.details-speakers {
  margin-bottom: 40px;
}

.details-info-list {
  margin-top: 34px;

  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg {
      width: 25px;
      height: 25px;
      margin-right: 11px;

      path {
        fill: $teal;
      }
    }

    .info-item-text {
      color: $text-3;
      font-size: 14px;
      line-height: 20px;

      & b {
        font-weight: 900;
      }
    }

    .details-info-link {
      font-size: 14px;
      line-height: 20px;
      color: $teal;
      text-decoration: none;
      cursor: pointer;

      &:visited {
        color: $teal;
        text-decoration: none;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.community-past-activities {
  display: flex;
  margin-bottom: 105px;

  .past-activity {
    display: flex;
    flex-direction: column;

    .past-activity-pic {
      width: 310px;
      height: 290px;
      border-radius: 10px;
      margin-bottom: 16px;
    }

    .past-activity-date {
      font-weight: 900;
      color: $teal;
    }

    .past-activity-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .past-activity:nth-child(2) {
    margin-left: 22px;
    margin-right: 22px;
  }
}

.details-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(
    100vh - #{$header-height} - #{$tabs-height} - #{$footer-height} - 200px
  );
}

.details-light-background {
  background-color: #ffffff;
}

.details-container {
  margin: 0 auto;
  padding: 24px $main-horizontal-padding 0
    ($main-horizontal-padding + $avatar-total-width);
  width: 100%;
  max-width: 1440px;

  & > .details-resources {
    width: 50%;
    margin-bottom: 58px;
  }
}

.details-footer-wrapper {
  background: #fff;

  .details-footer {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 180px 1fr 180px;
    -ms-grid-columns: 180px 1fr 180px;
    align-items: center;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    min-height: 120px;

    .details-switch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      max-width: 95px;
      transition: all 0.2s ease;

      &:focus {
        @include outline();
      }

      h3 {
        text-align: center;
        width: 145px;
      }

      &.prev {
        margin-left: 45px;
        justify-self: flex-start;
        grid-column: 1;
        -ms-grid-column: 1;
      }

      &.next {
        margin-right: 45px;
        justify-self: flex-end;
        grid-column: 3;
        -ms-grid-column: 3;
      }

      .details-switch-pic {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 2px solid transparent;
        margin-bottom: 10px;
      }

      &:hover {
        h3 {
          color: $teal-dark;
        }

        .details-switch-pic {
          border: 2px solid $teal-dark;
        }
      }
    }
  }
}

.action-responsibilities {
  display: flex;
  margin-top: 84px;
  padding-bottom: 320px;

  .responsibilities-list {
    display: flex;
    flex-direction: column;
    margin-right: 90px;
    min-width: 500px;

    & ul {
      & li {
        display: flex;
        align-items: baseline;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: $text-3;

        & p {
          margin-right: 10px;
        }
      }
    }
  }
}

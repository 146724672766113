@import './variables';

.input-component-wrapper {
  margin-bottom: $main-bottom-padding;

  &.radio {
    display: flex;
    align-items: center;
    margin: 0;

    input {
      margin: 0 8px 0 0;
      height: 16px;
      width: 16px;
    }

    .label::after {
      margin: 0;
    }
  }

  .label {
    color: $text-3;
    font-size: 15px;
    line-height: 25px;
    display: inline;

    &::after {
      content: '';
      display: block;
      margin-bottom: 12px;
    }

    &.validation-error {
      color: $red;
    }

    &.readonly {
      opacity: 0.5;
    }
  }

  .input-subline {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 25px;
    color: $text-5;
  }

  label + .input-subline {
    margin-top: -12px;
  }

  .input-wrapper {
    position: relative;

    &.flex {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 30px;
      }
    }

    .chars-count {
      position: absolute;
      top: -14px;
      right: 0;
      font-size: 13px;
      line-height: 18px;
      color: $text-5;
      transform: translate(0, -100%);
    }

    & > input:not([type='checkbox']):not([type='radio']):not([type='file']),
    & > textarea,
    .selectize-input,
    .react-datetime-picker,
    .address-field-wrapper input,
    .fake-input {
      padding: 10px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $text-3;
      border: 1px solid $border-color;
      border-radius: 1px;
      width: 100%;
      box-shadow: none;
      background-color: #fff;
      font-family: 'Lato', sans-serif;
      min-height: 42px;
      max-height: 42px;

      &::placeholder {
        font-size: 15px;
        line-height: 25px;
        color: $text-5;
      }

      &.validation-error {
        border-color: $red;
        background-color: #f8efef;
      }

      &:focus,
      &.focus {
        border: 1px solid $teal;
      }

      &.fake-input {
        background-color: $border-color;
      }

      &.selectize-input.disabled {
        opacity: 1;
        background-color: #f0f0f0;
      }
    }

    .required-hack-input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border: none !important;
    }

    &.recurring {
      & > input {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        width: 88px !important;
        max-height: 42px;
        flex: 0 1 !important;

        &:focus {
          border-right: 1px solid $teal !important;
        }
      }

      .selectize-control {
        width: 100%;
        max-width: 112px;
      }

      .selectize-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        max-width: 112px;
      }
    }

    .selectize-control {
      &.validation-error {
        .selectize-input {
          border-color: $red;
          background-color: #f8efef;
        }
      }
    }

    .react-select {
      &.validation-error {
        & > *:first-child {
          border-color: $red;
          background-color: #f8efef;
        }
      }
    }

    & > input:not([type='checkbox']):not([type='radio']):not([type='file']),
    & > textarea {
      &:read-only,
      &:disabled {
        background-color: #dadada;
      }
    }

    textarea {
      min-height: 190px;
    }

    .selectize-dropdown {
      box-shadow: none;
      border-color: $border-color;
      z-index: 4; //this needs to be lower than the header
      margin-top: 1px;

      &.timezones-select {
        width: 300px !important;
        border-top: 1px solid $border-color;
      }
    }
  }

  &.login {
    margin-bottom: 15px;

    &:first-of-type {
      margin-bottom: 24px;
    }

    .label {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &::after {
        margin-bottom: 10px;
      }
    }
    .input-wrapper {
      .with-link {
        position: absolute;
        top: -30px;
        right: 0;
      }

      & > input:not([type='checkbox']):not([type='radio']):not([type='file']) {
        min-height: 45px;
        padding: 8px 12px;
      }
    }
  }

  .validation-error-message {
    color: $red;
    font-size: 11px;
    line-height: 22px;
    display: block;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &.checkbox-component-wrapper {
    &:not(.vertical-checkbox) {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      justify-content: flex-end;
      align-items: flex-start;
      margin-bottom: 14px;
      line-height: 19px;

      .readonly {
        opacity: 0.5;
      }

      .label {
        font-size: 13px;
        line-height: 19px;
        width: auto;
        color: $text-5;
        cursor: pointer;
        display: inline-block;

        &::after {
          margin: 0;
        }
      }

      svg {
        vertical-align: top;
        margin-top: 2px;
      }

      &.full-size {
        margin-bottom: 24px;

        .label {
          color: #333;
          font-size: 15px;
        }

        .label.validation-error::after {
          margin-bottom: (19px + 5px);
        }

        .link {
          display: inline;
          line-height: 19px;
        }

        .validation-error svg {
          border-color: #c53c26;
        }

        .validation-error-message {
          position: absolute;
          top: (19px + 5px);
          width: 200px;
        }
      }

      // Terms and conditions checkbox should be centered
      .profile &.agree-to-terms {
        justify-content: center;
      }
    }

    .input-wrapper {
      flex: 0 0 15px;
      position: relative;
      height: 19px; /* not 15px, because of the border on the checkbox */
      width: 15px;
      display: block;
      margin-right: 12px;
      cursor: pointer;

      &:focus {
        svg {
          border-color: $teal;
        }
      }

      svg {
        height: 15px;
        width: 15px;
        border: 1.5px solid $text-9;
        border-radius: 2px;

        path {
          stroke: transparent;
        }
      }

      div {
        height: 15px;
        width: 15px;
        border-radius: 2px;
        border: 1.5px solid $text-9;
        background: none;
        margin-bottom: 1px;
      }

      input {
        display: none;
        appearance: none;

        &:checked {
          & ~ svg {
            background-color: $teal-dark;
            border-color: $teal-dark;

            path {
              stroke: #fff;
            }
          }

          & + div {
            background-color: $teal-dark;
            border-color: $teal-dark;
            background-image: url('../images/icons/checked.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }

      &.radio {
        svg {
          height: 15px;
          width: 15px;
          border: 1.5px solid $text-9;
          border-radius: 8px;

          path {
            stroke: transparent;
          }
        }

        &:not([disabled]):focus {
          svg {
            border-color: $teal !important;
          }
        }

        input {
          display: none;
          appearance: none;

          &:checked + svg,
          &:checked + .validation-error-message + svg {
            border: 1.5px solid $text-9;
            background-color: #fff;
            rect {
              fill: $teal-dark;
            }
          }
        }
      }

      &:after {
        display: none;
      }
    }

    &.login {
      margin-bottom: 21px;

      .label {
        font-size: 15px;
        line-height: 25px;
      }

      .input-wrapper {
        margin-right: 9px;
      }
    }
  }

  &.switch-component-wrapper {
    margin: 0 12px;

    .switch {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      input {
        appearance: none;
        display: none;

        &:checked {
          ~ .indicator {
            left: 18px;
          }

          ~ .back {
            background-color: $teal-dark;
          }
        }
      }

      .back {
        position: relative;
        display: block;
        width: 40px;
        height: 22px;
        border-radius: 11px;
        background: $text-9;
        padding: 5px;
        margin: 0;
      }

      .indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        background: #fff;
        border-radius: 50%;
        transition: 0.5s;
        margin: 4px;
      }
    }
  }

  &.search {
    position: relative;
    margin-bottom: 0;
    width: 266px;

    &:after {
      position: absolute;
      content: '';
      top: 13px;
      left: 15px;
      width: 18px;
      height: 18px;
      background-image: url('../images/icons/search.svg');
    }

    .clear {
      position: absolute;
      width: 11px;
      height: 11px;
      top: 17px;
      right: 17px;
      background-image: url('../images/icons/search-close.svg');
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }

    input:not([type='checkbox']):not([type='radio']):not([type='file']) {
      margin: 0;
      padding: 10px 27px 10px 50px;
      border-width: 1px;
      font-size: 14px;
      line-height: 20px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 15px;
        line-height: 25px;
        color: $text-5;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 15px;
        line-height: 25px;
        color: $text-5;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 15px;
        line-height: 25px;
        color: $text-5;
      }
    }
  }

  &.date-range-time-range {
    .input-wrapper {
      display: inline-block;
      position: relative;
      width: calc(50% - 13px);
      vertical-align: top;

      .date-picker {
        width: 66%;
        flex: 0 0 auto;
      }

      ~ .input-wrapper {
        margin-left: 26px;

        &::after {
          position: absolute;
          text-align: center;
          font-size: 14px;
          color: $text-5;
          top: 0;
          left: -26px;
          content: 'to';
          padding: 0 5px;
          height: 42px;
          width: 16px;
          line-height: 42px;
        }

        &.short::after {
          content: '';
        }
      }
    }
  }

  &.time-range {
    .time-input-wrapper {
      display: inline-block;
      position: relative;
      width: calc(50% - 9px);
      vertical-align: top;

      .time-picker {
        flex: 0 0 auto;
      }

      ~ .time-input-wrapper {
        margin-left: 18px;

        &::after {
          position: absolute;
          text-align: center;
          top: 0;
          left: -18px;
          content: '-';
          padding: 0 6px;
          height: 42px;
          width: 6px;
          line-height: 42px;
        }
      }
    }
  }

  &.timepicker-component-wrapper {
    .input-wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: 15px;
        width: 18px;
        height: 18px;
        background-image: url('../images/icons/clock.svg');
        background-position: center;
        background-size: cover;
      }

      input.timepicker {
        padding-left: 50px !important;
      }
    }
  }

  &.datepicker-component-wrapper {
    .input-wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: 15px;
        width: 17px;
        height: 18px;
        background-image: url('../images/icons/calendar_grey.svg');
        background-position: center;
      }

      input.datepicker {
        padding-left: 50px !important;
      }
    }
  }

  &.readonly {
    input {
      background-color: #dadada !important;
    }
  }
}
.selectize-control.single .selectize-input {
  input {
    font-size: 15px;
  }
  &:after {
    background-color: #fff;
    background-image: url('../images/icons/chevron_dark.svg');
    height: 6px;
    width: 10px;
    border: none;
  }

  &.dropdown-active:after {
    transform: rotate(180deg);
  }
}

.selectize-control.multi .selectize-input {
  &.has-items {
    padding: 6px 8px 3px;
  }

  &.dropdown-active:before {
    display: none;
  }

  & > input {
    min-width: 150px;
    width: 100% !important;
    opacity: 1 !important;
    position: relative !important;
    left: 0 !important;

    &::placeholder {
      font-size: 15px;
      line-height: 25px;
      color: $text-5;
    }
  }

  & > div {
    border-radius: 14px;
    background-color: $border-color;
    color: $text-5;
    font-size: 15px;
    line-height: 25px;

    .remove {
      border: none;
      font-size: 19px;
      margin: 0 2px;
      line-height: 1;

      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }
    }
  }
}

.selectize-dropdown {
  box-shadow: none;
  border-color: $border-color;
  z-index: 10000;
  margin-top: 1px;

  &.timezones-select {
    width: 300px !important;
    border-top: 1px solid $border-color;
  }

  .selectize-dropdown-emptyoptionlabel {
    text-align: left;
  }
}

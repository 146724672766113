@import 'variables';

@mixin spinner {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  background-image: url('../images/icons/spinner.svg');
  background-position: center center;
  background-size: contain;
  height: 20px;
  width: 20px;
  z-index: 10;
}

.file-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .preview {
    width: 100%;
    display: flex;
    align-items: center;

    &.loading {
      opacity: 0.5;
      pointer-events: none;

      .image-container {
        &:before {
          @include spinner;
        }
      }

      .link {
        &:after {
          @include spinner;
          top: auto;
          bottom: 10px;
          left: -10px;
        }
      }
    }

    .image-container {
      position: relative;
    }

    img {
      display: block;
      max-width: 100%;
      margin-bottom: 14px;
      height: 72px;
      width: 72px;
    }

    .link {
      width: 100%;

      &:before {
        flex: 0 0 14px;
        content: '';
        display: block;
        height: 14px;
        width: 14px;
        background-image: url('../images/icons/image.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 4px;
      }

      &.document {
        &:before {
          background-image: url('../images/icons/file.svg');
        }
      }
    }

    .remove {
      height: 11px;
      width: 11px;
      background-image: url('../images/icons/close-dark.svg');
      background-size: cover;
      flex: 0 0 11px;
      margin-left: 4px;
    }
  }

  &.round {
    .preview {
      img {
        border-radius: 50%;
      }
    }
  }

  &.banner {
    .preview {
      img {
        width: 580px;
      }
    }
  }

  &.tile {
    .preview {
      img {
        width: 132px;
      }
    }
  }

  label {
    display: block;
    cursor: pointer;

    input[type='file'] {
      height: 0px;
      width: 1px;
      position: absolute;
      top: -2px;
    }
  }
}

.custom-cropper {
  width: 500px;
  height: 400px;

  .cropper-modal {
    opacity: 0.6;
  }

  .cropper-preview {
    & > img {
      display: block;
      width: 500px;
      height: 400px;
    }

    &.round {
      .cropper-view-box {
        border-radius: 50%;
      }
    }

    &.tile {
      .cropper-view-box {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 7.5%; // Padding of 12px on a 160px height tile
          left: 3.7%; // Padding of 12px on a 308px width tile
          height: 31.2%; // Image of 50px on a 160px height tile
          width: 16.2%; // Image of 50px on a 308px width tile
          border-radius: 50%;
          background: url('../images/icons/logo_placeholder.svg') center / 100%
            no-repeat $background-grey;
        }
      }
    }
  }
}

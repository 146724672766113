@import 'variables';

.swal2-backdrop-show {
  background-color: rgba($text-3, 0.65) !important;
}

.swal2-show {
  animation: none;
}

.swal2-popup {
  padding: 36px;
  border-radius: 4px;

  .swal2-header {
    align-items: flex-start;

    &.neutral {
      margin-bottom: 0px;
      padding: 0px;

      .swal2-title {
        font-size: $fs-paragraph;
        color: $text-3;
      }
    }
  }

  .swal2-content {
    padding: 0;
    z-index: 2;
  }

  .swal2-actions {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin: 0;

    @media (max-width: 431px) {
      .button {
        margin-left: auto;
        margin-top: 12px;
        flex: 1;
      }
    }

    .button {
      margin-left: 11px;
    }
  }

  .swal2-title {
    text-align: left;
  }
}

.simple-modal-body {
  text-align: left;
  max-width: $max-form-width;
}

.deactivate-modal-body {
  .subtitle {
    margin-bottom: 8px;
  }

  .message {
    margin-top: 8px;
  }

  .warning {
    margin-top: 32px;
  }

  ul {
    list-style: disc;
    padding-left: 32px;

    li {
      font-size: 15px;
      line-height: 25px;
    }
  }
}

.register-modal-body {
  max-width: none;

  h1 {
    margin-top: 24px;
    margin-bottom: 8px;

    &.event-title {
      color: $blue;
      letter-spacing: 1px;
    }

    &.call-title {
      color: $orange-dark;
    }

    &.community-title {
      margin-bottom: 15px;
      color: $teal-dark;
    }
  }

  h2 {
    margin-bottom: 8px;

    &.event-time {
      color: $blue;
      letter-spacing: 1px;
    }

    &.call-time {
      color: $orange-dark;
    }

    &.community-subtitle {
      color: $teal-dark;
    }
  }

  p {
    font-size: $fs-paragraph;
    color: $text-3;
  }

  .input-component-wrapper .label {
    font-size: $fs-paragraph;
    color: $text-3;
  }

  .info {
    margin-bottom: $grid-gap-basis;

    .location {
      display: flex;
      align-items: center;

      .text {
        position: relative;
        padding-left: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 14px;
          height: 25px;
          background-image: url('../images/icons/location_event.svg');
          background-position: center;
          background-size: 14px 14px;
          background-repeat: no-repeat;
        }

        .label {
          margin-right: calc($grid-gap-basis / 2);
        }
      }

      &.location-virtual {
        .link {
          display: inline;
        }

        .text::after {
          background-image: url('../images/icons/video_call_event.svg');
        }
      }

      &.call .text::after {
        background-image: url('../images/icons/location_call.svg');
      }

      &.location-virtual.call .text::after {
        background-image: url('../images/icons/chain_45_call.svg');
      }
    }

    .allies {
      width: 171px;
      height: 16px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../images/icons/allies.svg');
      margin-bottom: 24px;
    }
  }

  .hosts {
    margin-bottom: $grid-gap-basis;

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: $grid-gap-basis;
      }

      .host-logo {
        width: $logo-size-sm;
        height: $logo-size-sm;
        border-radius: 50%;
        margin-right: calc($grid-gap-basis / 2);
      }

      .host-name {
        color: $text-3;
        font-size: $fs-paragraph;
        line-height: $line-height-paragraph;
      }
    }

    .has-more {
      display: inline-block;
      margin-top: $grid-gap-basis;
      color: $text-3;
      font-size: $fs-paragraph;
    }
  }

  .additional-inputs {
    margin-top: $grid-gap-basis * 2;
    padding-top: $grid-gap-basis * 2;
    border-top: 1px solid $border-color;
  }

  #invitation-form {
    width: 100%;

    .selectize-input {
      max-height: 170px;
      overflow: auto;
    }

    input:not([type='checkbox']) {
      line-height: 16px !important;
    }

    .checkbox-component-wrapper {
      svg {
        use {
          transform: translate(-2px, -2px);
        }
      }
    }
  }
}

.badges-modal-body {
  margin-top: 52px;
  width: 768px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 431px) {
    width: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 33px;
    left: 0;
    width: 130px;
    height: 95px;
    background-image: url('../images/icons/modal_two_squares.svg');
  }

  &::after {
    content: '';
    position: absolute;
    top: 140px;
    right: 0;
    width: 160px;
    height: 140px;
    background-image: url('../images/icons/modal_three_squares.svg');
  }

  &.no-background {
    margin: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;
  }

  .share-button {
    margin: $grid-gap-basis 0;
    line-height: 14px;
  }

  .share-logo {
    height: 14px;
    width: 14px;
    margin-right: calc($grid-gap-basis / 2);
  }
}

.speaker-bio-modal {
  text-align: left;

  .bio-header {
    height: 0;
  }

  .speaker-info {
    display: flex;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 25px;

    img {
      display: block;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      margin-right: 12px;
      flex: 0 0 60px;
    }

    h3,
    p {
      width: 100%;
      color: $text-3;
    }
  }

  .bio-text {
    max-width: 100%;
    margin-bottom: 7px;
    white-space: pre-wrap;
  }
}

.add-bio-modal-body,
.batch-email-modal,
.email-leader-modal {
  .input-component-wrapper {
    &:first-child {
      margin-top: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    #add-bio-textarea,
    #email-message {
      width: 600px;
      max-width: 100%;
      min-height: 145px;
    }
  }
}

.edit-log-modal {
  max-width: none;
  margin: 20px -36px 0;
  width: 696px;

  $edit-log-modal-padding: 56px;

  .loading-indicator {
    padding: 0 $edit-log-modal-padding;
  }

  .GojiCustomTable-wrapper {
    box-shadow: none;

    .GojiCustomTable tr {
      th {
        &:first-child {
          padding-left: $edit-log-modal-padding;
        }
      }

      td:first-child .GojiCustomTable__cell-value:first-child {
        padding-left: $edit-log-modal-padding;
      }
    }
  }
}

.modal {
  .header {
    margin-bottom: 16px;
    padding: 0;
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: $teal-dark;
    margin: 0;
    font-weight: 700;
  }

  &.close {
    margin-top: 10px;
    margin-right: 15px;
    font-size: 30px;
    font-weight: 700;
    color: transparent;
    background-image: url('../images/icons/close-dark.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-radius: 50%;
      border: 1px solid #fff;
    }
  }

  .error-message {
    color: $red;
  }

  &.content {
    padding: 0 !important;
    text-align: left;
  }

  @media (max-width: 431px) {
    .actions {
      display: flex;
      gap: $grid-gap-basis;
    }
  }

  .actions {
    padding: 0;
    margin-top: 24px;
  }
}

.community-subscription-preferences-modal {
  .input-component-wrapper {
    margin-bottom: 0;
  }

  .disclaimer {
    margin-top: 16px;
    font-style: italic;
  }
}

.reset-password-modal {
  display: flex;
  align-items: flex-end;

  & > div {
    &:first-child {
      margin-right: 58px;

      .body {
        margin-bottom: 16px;
      }
    }
  }

  .password-rules {
    font-size: 13px;
    line-height: 18px;
    width: 240px;
    flex: 0 0 auto;
    margin-bottom: 50px;

    ul {
      list-style: disc;
      padding-left: 25px;
    }
  }
}

.allies-affiliates-modal {
  .disclaimer {
    margin-top: 12px;
  }

  .allies-affiliates-communities {
    margin: 24px 0 12px;
    font-size: 15px;
  }

  .allies-affiliates-community-item {
    border-top: 1px solid $border-color;
    padding: 12px 0;

    .allies-affiliates-community-details {
      display: flex;
    }

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    .allies-affiliates-icon {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      margin-right: 12px;
    }

    .allies-affiliates-name {
      line-height: 42px;
      margin-right: 12px;

      @include ellipsis();
    }

    .allies-affiliates-field {
      width: 300px;
      margin: 12px 0 0;
    }
  }
}

@import 'variables';

.flash {
  position: fixed;
  z-index: 1000;
  height: $flash-height;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: $header-height;
  padding: 0 $main-horizontal-padding;
  color: #fff;

  @media (max-width: 431px) {
    padding: $grid-gap-basis;
    height: fit-content;
  }

  &_type_alert {
    background-color: rgba($red, 1);
  }

  &_type_notice,
  &_type_info,
  &_type_success,
  &_type_preview,
  &_type_draft {
    background-color: rgba($text-3, 0.65);

    .message {
      font-weight: bold;
      font-size: 15px;
      line-height: 25px;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        height: 16px;
        min-width: 16px;
        background-color: #fff;
        background-image: url('../images/icons/checked-black.svg');
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 7px;
        border-radius: 50%;
      }
    }

    .not-styled-button {
      color: #ffffff;
      font-size: 15px;
      line-height: 25px;
      display: flex;
      align-items: center;

      & > * {
        margin-left: 10px;
      }
    }
  }

  &_type_draft,
  &_type_preview {
    background-color: $teal-dark;
  }

  &_type_preview:after {
    content: '';
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

// font-families
$font-family-poppins: 'Poppins', sans-serif;
$font-family-lato: 'Lato', sans-serif;

// colors
$background-white: #ffffff;
$background-main: #fafafa;
$border-color: #e7e7e7;
$background-page: #f9f9f9;
$background-grey: #f0f0f0;
$background-highlight: #f4f4f4;

$green: #1d5d57;
$green-bg-light: #e3fffa;
$green-bg-very-light: #f1f7f6;

$teal: #4AA199;
$teal-light: #d8f0ed;
$teal-dark: #2e756e;
$teal-bg: #f6fbfb;
$teal-image: #a8ddd8;

$blue: #1A6A99;
$blue-hover: #277CB4;
$blue-light: #56b4e9;
$blue-dark: #155880;
$blue-bg-light: #eef7fc;

$orange: #AE610A;
$orange-dark: #de7c0d;
$orange-light: #f8c489;
$orange-extra-light: #fbdebd;

$red: #c53c26;
$red-light: #f8efef;
$red-dark: #700404;
$violet: #985ebc;

$pink: #CC4072;

$purple: #aa51b9;
$purple-dark: #ab3abd;
$purple-light: #fcf0ff;

$yellow: #ad5e01;
$yellow-light: #fff7ee;

// text
$text-9: #999999;
$text-5: #555555;
$text-3: #333333;
$text-7: #7c7c7c;

// shadow
$shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
$shadow-right: 3px 2px 6px -2px rgba(0, 0, 0, 0.1);
$shadow-hover: 0px 12px 17px rgba(0, 0, 0, 0.06),
  0px 5px 22px rgba(0, 0, 0, 0.06), 0px 7px 8px rgba(0, 0, 0, 0.1);

// sizes
$main-vertical-padding: 40px;
$main-horizontal-padding: 60px;
$grid-padding: 26px;
$main-bottom-padding: 24px;
$sidebar-width: 328px;
$collapsed-sidebar-width: 100px;
$footer-height: 80px;
$header-height: 42px;
$max-form-width: 580px;
$tabs-height: 53px;
$grid-gap-basis: 12px;
$logo-size-sm: 30px;
$card-padding: $grid-gap-basis * 2;
$box-radius: 4px;
$icon-size: 18px;
$flash-height: 80px;

// font-sizes, line-heights
$fs-h1: 32px;
$fs-h2: 24px;
$fs-pill-small: 10px;
$fs-pill-large: 13px;
$fs-paragraph: 15px;
$line-height-h1: 34px;
$line-height-h2: 30px;
$line-height-paragraph: 25px;

//=====
$details-padding: 172px;

// Pill sizing
$pill-small-padding-vertical: 4px;
$pill-small-height: $fs-pill-small + ($pill-small-padding-vertical * 2) + 2px;
$pill-large-padding-vertical: 5px;
$pill-large-height: $fs-pill-large + ($pill-large-padding-vertical * 2) + 2px;

//Z-index
$side-bar-z-index: 40;

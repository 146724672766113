@import 'variables';

.mindr-rich-content {
  font-family: $font-family-lato;
  color: $text-5;

  h1 {
    margin: 16px 0;
    font-family: $font-family-poppins;
    font-weight: bold;
    font-size: $fs-h1;
    line-height: $line-height-h1;
  }

  h2 {
    margin: 12px 0;
    font-family: $font-family-poppins;
    font-weight: bold;
    font-size: $fs-h2;
    line-height: $line-height-h2;
  }

  ol {
    list-style: decimal;
  }

  ol ol {
    list-style: lower-alpha;
  }

  ol ol ol {
    list-style: lower-roman;
  }

  ul,
  ol {
    margin: 12px 0;
    padding: 0 0 0 40px;
    font-size: $fs-paragraph;
    line-height: $line-height-paragraph;
  }

  li ol,
  li ul {
    margin: 0;
  }

  p {
    margin: 12px 0;
    font-size: $fs-paragraph;
    line-height: $line-height-paragraph;
  }
}

@import '../variables';

.survey {
  & > p {
    margin-left: 88px;
    margin-top: 48px;
  }

  & > .mindr-form {
    padding-bottom: 120px;

    & > section {
      padding: 0 88px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .survey-title {
      margin-top: 24px;
      margin-bottom: 12px;
      font-weight: 700;
      text-transform: none;

      &.validation-error {
        color: $red;
      }

      &.last {
        margin-top: 32px;
      }
    }

    .input-component-wrapper {
      margin-bottom: 12px;

      &.validation-error {
        .input-wrapper.radio {
          svg {
            border-color: $red;
          }
        }
      }

      .validation-error-message {
        display: none;
      }

      .label {
        font-size: 13px;
        line-height: 18px;
      }

      &:last-child {
        margin-bottom: 0;
        margin-top: 24px;
      }
    }
  }
}

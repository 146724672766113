@import '../variables';

.badges-sub-header {
  font-size: 15px;
  line-height: 1.67;
  margin-bottom: 40px;
  color: $text-5;
}

.timelines {
  margin-bottom: 68px;
}

.badge-manager-denied {
  background-color: $red-light;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $red !important;
  padding: 2px 12px;
  font-size: 10px !important;
  font-weight: 700 !important;
  font-family: 'Lato', sans-serif !important;
  text-transform: uppercase;
}

.badge-timeline {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $shadow;
  padding: 32px 36px 28px;
  width: 1010px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  & > .badge-content {
    display: flex;
    align-items: baseline;
    margin-top: 4px;

    .timeline {
      margin-right: 45px;
      width: 100%;
      max-width: 500px;

      .timeline-content {
        margin-top: 9px;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        min-width: 500px;
        max-width: min-content;
        background-image: url('../images/icons/timeline.svg');
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        .ten-lines {
          position: absolute;
          bottom: 4px;
          right: -13px;
          stroke: $border-color;
        }

        & > .item {
          width: 44px;
          min-width: 44px;
          height: 44px;
          background-color: #fff;
          border: 4px solid $border-color;
          border-radius: 22px;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 36px;
          color: $text-3;
          display: flex;
          display: -ms-flexbox;
          justify-content: center;
          align-items: center;

          &:nth-child(-n + 5) {
            margin-right: 56px;
            margin-bottom: 44px;
          }

          &:nth-child(n + 6) {
            margin-left: 56px;
          }

          &.acquired {
            color: #ffffff;
          }
        }
      }
    }

    &.events-register {
      .timeline {
        .timeline-content > .item {
          &:nth-child(-n + 5).acquired {
            background-color: $teal-light;
            border-color: $teal-light;
            color: $teal-dark;
          }

          &:nth-child(n + 6).acquired {
            background-color: $teal;
            border-color: $teal;
          }

          &:last-of-type.acquired ~ .ten-lines {
            stroke: $teal;
          }
        }
      }
    }

    &.events-submit {
      .timeline {
        .timeline-content > .item {
          &:nth-child(-n + 5).acquired {
            background-color: $blue-light;
            border-color: $blue-light;
          }

          &:nth-child(n + 6).acquired {
            background-color: $blue;
            border-color: $blue;
          }

          &:last-of-type.acquired ~ .ten-lines {
            stroke: $blue;
          }
        }
      }
    }

    &.cta-register {
      .timeline {
        .timeline-content > .item {
          &:nth-child(-n + 5).acquired {
            background-color: $orange-extra-light;
            border-color: $orange-extra-light;
            color: $text-5;
          }

          &:nth-child(n + 6).acquired {
            background-color: $orange-dark;
            border-color: $orange-dark;
            color: $text-5;
          }

          &:last-of-type.acquired ~ .ten-lines {
            stroke: $orange-dark;
          }
        }
      }
    }

    &.cta-invite {
      .timeline {
        .timeline-content > .item {
          &:nth-child(-n + 5).acquired {
            background-color: $violet;
            border-color: $violet;
          }

          &:nth-child(n + 6).acquired {
            background-color: $red-dark;
            border-color: $red-dark;
          }

          &:last-of-type.acquired ~ .ten-lines {
            stroke: $red-dark;
          }
        }
      }
    }

    .badges {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        margin-left: 54px;
        align-items: flex-start;

        & > .highlighted {
          margin-bottom: 16px;
        }
      }

      & > .highlighted {
        margin-bottom: 9px;
        font-weight: 700;
        font-size: 15px;
        line-height: 1.67;
        color: $orange;
      }
      & > .badge {
        &:not(.acquired) {
          svg {
            width: 80px;
            height: 80px;
            fill: $text-9;
          }
        }

        &.acquired {
          position: relative;

          svg {
            width: 80px;
            height: 80px;
          }

          &::after {
            position: absolute;
            content: '';
            top: 6px;
            right: -3px;
            width: 21px;
            height: 21px;
            background-color: #ffffff;
            background-image: url('../images/icons/check_blue.svg');
            background-repeat: no-repeat;
          }
        }

        &:nth-child(3) {
          margin-top: 7px;
        }
      }

      & > .status-text {
        font-size: 13px;
        line-height: 1.38;
        color: $text-3;

        &:last-child {
          margin-top: 27px;
        }

        &.leadership {
          margin-top: 0;

          &:last-child {
            margin-top: 0 !important;
          }
        }

        & > p {
          margin-bottom: 5px;

          &:only-of-type {
            margin-top: 16px;
          }
        }
      }
    }
  }
  &.leadership {
    padding-bottom: 35px;

    & > .badge-content {
      align-items: flex-start;
      margin-top: -4px;

      .timeline {
        max-width: 165px;
        margin-right: 25px;
        margin-top: 20px;

        .caption {
          font-size: 13px;
          line-height: 18px;
        }
      }

      & > .badges {
        &:nth-child(3) {
          align-items: flex-start;
          margin-left: 55px;
          margin-right: 90px;
        }

        &:nth-child(5) {
          align-items: flex-start;
          margin-left: 55px;
        }

        & > .highlighted {
          margin-bottom: 8px;
        }

        & > .status-text:last-child {
          margin-top: 16px;
        }
      }
    }
  }
}

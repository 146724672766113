@import './variables';
@import './mixins';

.button {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  min-width: 90px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 10px 24px;
  text-align: center;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  margin: 0; // required to override Safari's default button styling

  & > svg {
    margin-right: 12px;

    &:not(.logo) {
      path {
        fill: currentColor;
      }
    }
  }

  &:active {
    outline: none;
  }

  &:focus {
    opacity: 0.95;
    border: 2px solid $teal !important;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.small {
    padding: 5px 10px;
  }

  &.primary {
    background-color: $teal;
    border-color: $teal;
    color: #fff;

    &:focus,
    &:hover {
      background-color: $teal-dark;
      border-color: $teal-dark;
    }

    &.inverted {
      border-color: #fff;
      background-color: #fff;
      color: $teal;
    }

    &:focus {
      border-color: $teal-dark !important;
    }
  }

  &.red {
    background-color: $red;
    border-color: $red;
    color: #fff;

    &:focus,
    &:hover {
      background-color: $red-dark;
      border-color: $red-dark;
    }
  }

  &.cancel {
    background-color: $background-grey;
    border-color: $background-grey;
    color: $text-5;

    &:hover {
      background-color: $border-color;
      border-color: $border-color;
    }
  }

  &.secondary {
    background: transparent;
    border-color: $teal;
    color: $teal-dark;

    &:focus,
    &:hover {
      color: $teal-dark;
      border-color: $teal-dark;
      background-color: $background-grey;
    }

    &.inverse {
      border-color: #ffffff;
      color: #ffffff;

      &:focus,
      &:hover {
        color: $teal-dark;
      }
    }

    &.plus {
      &::before {
        width: 20px;
        height: 20px;
        margin-right: calc($grid-gap-basis / 2);
        content: '';
        background: center no-repeat url('../images/icons/plus.svg');
      }
    }
  }

  &.light {
    border: none !important;
    background: $teal-light;
    color: $teal-dark;

    &:hover,
    &:focus {
      background: $teal;
      color: white;
    }
  }

  &.outlined-blue {
    border-width: 0.5px;
    background: transparent;
    border-color: $blue;
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;

    &:hover {
      color: $blue-dark;
      border-color: $blue-dark;
    }
  }

  &.with-icon {
    &::before {
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      margin-right: calc($grid-gap-basis / 2);
    }
  }
}

.not-styled-button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-align: inherit;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    @include outline();
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: -19px;
    width: 11px;
    height: 11px;
  }
}

.subscribe-community-btn,
.register-event-call-btn {
  .subscribed-content {
    display: none;
  }

  &.subscribed {
    .subscribed-content {
      display: block;
    }

    .not-subscribed-content {
      display: none;
    }
  }

  &.on-card {
    position: absolute;
    bottom: 24px;
    right: 24px;

    .not-subscribed-content {
      background: $teal-light;
      height: 29.5px;
      min-width: 30px;
      border-radius: 15px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: center no-repeat url('../images/icons/plus.svg');
      }

      &.call::after {
        background: center no-repeat url('../images/icons/checked_teal.svg');
      }

      &:hover {
        background: $teal;

        &::after {
          background: center no-repeat url('../images/icons/plus_white.svg');
        }

        &.call::after {
          background: center no-repeat url('../images/icons/checked.svg');
        }
      }
    }
  }
}

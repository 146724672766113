@import 'variables';

@mixin badge($text, $background: $teal-dark) {
  position: absolute;
  content: $text;
  border-radius: 4px;
  padding: 2px 15px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
  color: #fff;
  top: 0;
  right: 0;
  width: fit-content;
  background-color: $background;
}

@mixin title() {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: $text-5;
}

@mixin outline() {
  outline: 2px solid $teal;
  outline-offset: 2px;
}

@mixin responsive($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@import '../variables';
@import '../mixins';

@import 'details';
@import 'events';
@import 'surveys';

.home {
  width: 100%;

  &.white {
    background-color: #ffffff;
  }

  .communities-slider-wrapper {
    background-color: $background-highlight;

    &.empty-communities {
      padding-bottom: 36px;
    }
  }

  .home-communities {
    .details-resources {
      width: calc(50% - 12px);
      margin-bottom: 85px;
    }
  }

  .community-card-wrapper {
    padding: 0 11px 34px;
    height: 100%;
  }

  .events-and-actions-wrapper {
    display: grid;
    display: -ms-grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    -ms-grid-columns: calc(50% - 12px) 24px calc(50% - 12px);
    grid-gap: 24px;
    margin-top: 28px;

    .events-and-actions {
      display: block;
      flex-direction: column;

      &:nth-child(1) {
        -ms-grid-column: 1;
      }

      &:nth-child(2) {
        -ms-grid-column: 3;
      }

      .items-wrapper {
        margin-bottom: 60px;

        .event:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .no-found {
    margin: 80px auto;
    padding: 36px 172px;
    background: #fff;
    border-radius: 4px;
    box-shadow: $shadow;
    max-width: 580px;

    .no-found-text {
      color: $text-3;
      text-align: center;
      font-size: 12px;
      line-height: 22px;
      font-weight: 700;
      max-width: 234px;
    }
  }

  .slider {
    position: relative;
    padding: 0;
    margin-right: -11px;
    margin-left: -11px;

    &.homepage-communities {
      .slick-slide {
        max-width: 360px;

        & > div {
          height: 100%;
        }
      }
    }

    .slick-track {
      margin-right: 0;
      margin-left: 0;
    }

    .slick-arrow {
      position: absolute;
      top: calc(50% - 15px);
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      cursor: pointer;

      &:hover,
      &:focus {
        &::before {
          position: absolute;
          top: -5px;
          left: -5px;
          width: 40px;
          height: 40px;
          content: '';
          border-radius: 20px;
          background-color: $teal-light;
          z-index: -3;
        }
      }

      &:active {
        svg {
          path {
            stroke: #fff;
          }
        }

        &::before {
          position: absolute;
          top: -5px;
          left: -5px;
          width: 40px;
          height: 40px;
          content: '';
          border-radius: 20px;
          background-color: $teal;
          z-index: -3;
        }
      }

      &.slick-prev {
        left: -34px;

        &.img {
          left: -48px;
        }
      }

      &.slick-next {
        right: -34px;

        &.img {
          right: -48px;
        }
      }
    }
  }

  .community-events-slider {
    .event:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }
  }
}

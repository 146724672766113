@import 'variables';

.mindr-details {
  padding: 56px $main-horizontal-padding 120px;

  .buttons-container {
    display: flex;
    align-items: center;

    .mini {
      max-height: 40px;
      line-height: 12px;
    }

    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  .header {
    h1 {
      margin-top: 24px;
      margin-bottom: 8px;
    }

    h2 {
      margin-bottom: 16px;

      &.event-header {
        color: $blue;
      }

      &.call-header {
        color: $orange-dark;
      }

      &.meeting-header {
        color: $teal;

        &.community-type-cohort {
          color: $purple;
        }
      }
    }

    .meta {
      margin-bottom: 56px;

      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 10px;

          path {
            fill: $blue;
          }
        }

        .link {
          display: inline-block;
          margin-left: 3px;
        }

        &.call {
          svg {
            path {
              fill: $orange-dark;
            }
          }
        }
        &.meeting {
          svg {
            path {
              fill: $teal;
            }
          }
        }
      }

      &.community-type-cohort > .item.meeting {
        svg {
          path {
            fill: $purple;
          }
        }
      }
    }
  }

  section {
    display: flex;
    width: 100%;
    margin-bottom: 56px;

    &.banner {
      margin-bottom: calc($grid-gap-basis * 2);
    }

    & > div {
      width: 100%;
    }

    & > .left {
      width: 100%;
      max-width: 33%;

      &.events,
      &.meetings {
        max-width: none;
        padding-right: $grid-gap-basis * 2;
      }

      .details-resources {
        margin-right: 32px;
        margin-top: 40px;
      }

      .statistic-widget {
        &.events {
          //TODO: Get rid of this margin once CTA has been redesigned
          margin: 0;
        }

        &.old {
          margin-right: 32px;
        }

        & + .statistic-widget {
          margin-top: $grid-padding;
        }
      }
    }

    > .right {
      width: 100%;
      max-width: 67%;

      &.events,
      &.meetings {
        flex: 0 0 458px;

        .card {
          max-width: 450px;
        }
      }

      .waitlist {
        margin-top: 5rem;
      }
    }
  }

  .statistic-widget {
    &.old {
      background: #ffffff;
      box-shadow: $shadow;
      border-radius: 4px;
      padding: 20px 16px;
      display: flex;
      align-items: center;

      .icon {
        background-color: $blue-light;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        flex: 0 0 40px;

        &.dark {
          background-color: $blue-dark;
        }

        svg {
          height: 18px;
          width: 18px;

          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  &.report-details {
    .section-header {
      align-items: flex-start;

      h2 {
        max-width: 440px;
        margin: 8px 0;
      }

      .time {
        color: $blue;
        font-weight: bold;
      }
    }

    section {
      display: flex;

      & > div {
        flex: 1;
      }

      .widgets-row {
        display: flex;
        margin-bottom: $main-bottom-padding;

        .statistic-widget {
          flex: 1;

          .icon {
            background-color: $teal-light;

            svg {
              path {
                fill: $teal;
              }
            }
          }

          & + .statistic-widget {
            margin-left: $grid-padding;
          }
        }
      }
    }

    .feedback-container {
      border-radius: 4px;
      box-shadow: $shadow;

      .feedback-header {
        border-radius: 4px 4px 0 0;
        background-color: #ffffff;
        padding: 11px 47px;
        font-weight: 700;
        font-size: 15px;
        line-height: 25px;
        color: $orange-dark;
        border-bottom: 1px solid $border-color;
      }

      .feedback-list {
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        max-height: 420px;
        overflow: auto;

        .feedback-item {
          padding: 22px 48px;

          &:not(:last-child) {
            border-bottom: 1px solid $border-color;
          }
        }
      }

      & + .feedback-container {
        margin-top: $grid-padding;
      }
    }
  }

  &.meeting-details {
    .additional-notes {
      padding: 12px 0;
      max-width: 640px;
    }

    .details-resources {
      max-width: 50%;
    }

    .meeting-minutes-table .GojiCustomTable tbody {
      &:first-of-type {
        td {
          &:first-child {
            .GojiCustomTable__cell-value {
              font-weight: bold;
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
      }

      td {
        vertical-align: top;

        &:nth-child(1),
        &:nth-child(2) {
          width: 200px;
        }

        &:nth-child(3) {
          width: 440px;
        }
      }
    }

    .agenda-table {
      .GojiCustomTable tbody {
        td {
          &:nth-child(1),
          &:nth-child(3) {
            width: 200px;
          }

          &:nth-child(2) {
            width: 150px;
          }

          &:nth-child(4) {
            width: 440px;
          }
        }
      }
    }
  }

  &.event-details,
  &.meeting-details {
    section.event-table {
      td {
        vertical-align: top;
        &:nth-child(1) {
          width: 200px;
        }
        &:nth-child(2) {
          width: 150px;
        }
        &:nth-child(3) {
          width: 440px;
        }
      }

      tbody tr td {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &.bold {
        tbody tr td:first-child {
          font-weight: 700;
        }
      }
    }
  }

  .widget-row {
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gap-basis * 2;
    .widget {
      flex: 1;
      padding-bottom: $grid-gap-basis;

      &.last {
        padding-right: 0;
      }
    }
  }

  .events-card:not(:first-child),
  .meetings-card {
    padding-top: $grid-gap-basis * 3;
  }
}

.menu-wrapper {
  position: relative;
  z-index: 4; //lower than header nav

  .menu-body {
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: $shadow;
    border-radius: 4px;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 250ms ease-in-out;

    &.opened {
      transform: scale(1, 1);

      .menu-link {
        opacity: 1;
        transition: opasity 100ms ease-in-out;
      }
    }

    .menu-link {
      display: block;
      padding: 14px 25px;
      min-width: 266px;
      background-color: #ffffff;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $text-3;
      border: none;
      border-bottom: 1px solid #eaeaea;
      outline: none;
      cursor: pointer;
      transition: opasity 100ms ease-in-out 100ms;
      text-align: left;
      margin: 0;
      font-family: 'Lato', sans-serif;

      &:hover,
      &:focus {
        background-color: $teal-bg;
      }
    }

    .menu-item {
      &:first-child {
        .menu-link {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &:last-child {
        .menu-link {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border: none;
        }
      }
    }
  }

  .menu-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $teal;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }

    &:focus,
    &:hover {
      outline: none;
      background-color: $background-grey;
      border: 2px solid $teal-dark;
    }
  }
}

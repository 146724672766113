@import '../variables';

.main.with-login {
  margin-bottom: $footer-height;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
}

.login-wrapper {
  width: 100%;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  background: url('../images/login_bg.jpg'),
    linear-gradient(rgba($text-3, 0.45), rgba($text-3, 0.45));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.login-container {
  margin: 120px auto;
  padding: 60px 121px 43px;
  width: 100%;
  max-width: 622px;
  color: $text-3;
  background-color: #ffffff;
  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  & > p {
    margin-bottom: 55px;
  }

  .organization-header {
    display: flex;
    // align-items: center;

    img {
      width: 60px;
      height: 60px;
      background-color: $background-white;
      border-radius: 50%;
      margin-right: $grid-gap-basis;
    }

    h1 {
      line-height: 60px;
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  &.bottom-padding {
    padding-bottom: 40px;
  }

  .form-title {
    margin: ($grid-gap-basis * 3) 0 ($grid-gap-basis * 2);
  }

  .decorated {
    margin-top: 13px;
    margin-bottom: 18px;
    align-self: center;
    text-align: center;
    width: 100%;
    position: relative;

    &::before {
      content: '';
      height: 2px;
      width: 129px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: $border-color;
    }

    &::after {
      content: '';
      height: 2px;
      width: 129px;
      position: absolute;
      top: 50%;
      right: 0;
      background-color: $border-color;
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-top: 24px;
  }
}

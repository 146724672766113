@import 'variables';
@import 'mixins';
@import 'mindr-form';
@import 'mindr-details';
@import 'table';
@import 'custom-cropper';
@import 'dashboard/badges';

@mixin nav-button {
  padding: 14px 10px 14px $main-horizontal-padding;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: $text-3;
  text-decoration: none;

  svg {
    margin-right: 14px;
    height: 17px;
    width: 17px;

    path {
      fill: $text-3;
    }
  }

  &:hover,
  &:focus {
    background-color: $background-grey !important;
    color: $text-5 !important;
  }

  &.active {
    background-color: $orange-extra-light;
    font-weight: 700;
  }
}

aside {
  &.sidebar {
    width: $sidebar-width;
    height: calc(100% - #{$header-height});
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    overflow: auto;
    padding: $main-vertical-padding 0 0;
    box-shadow: $shadow;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      flex: 0 0 auto;
    }

    .basic-info {
      padding: 0 30px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .upload-avatar-component {
        margin-bottom: 19px;
      }

      .avatar {
        height: 144px;
        width: 144px;
        margin-bottom: 19px;
        border-radius: 50%;
        flex: 0 0 144px;
      }

      .link {
        display: block;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 36px;
        align-self: flex-start;
        margin-left: $main-horizontal-padding - 30px;
      }

      .profile-link {
        text-decoration: none;
      }

      .title {
        padding: 0 ($main-horizontal-padding - 30px);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        color: $text-5;
      }
    }

    nav {
      .section {
        border-top: 1px solid $border-color;
        padding: 36px 0;
      }

      .caption {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 16px;
        font-size: 9px;
        letter-spacing: 0.5px;
        color: $text-5;
        margin-bottom: 8px;
        margin-left: $main-horizontal-padding;
      }

      ul {
        li {
          a {
            @include nav-button;
          }

          &.icon-stroke {
            a {
              svg {
                path {
                  stroke: $text-3;
                  fill: none;
                }

                path.fill-path {
                  stroke: none;
                  fill: $text-3;
                }
              }
            }
          }
        }
      }
    }

    .logout {
      padding: 14px 10px 14px $main-horizontal-padding;
    }

    .empty-block-for-ie {
      padding-bottom: $footer-height;
    }
  }
}

.leadr-index-report {
  background-color: #fff;
}

.main.with-sidebar,
.main.without-sidebar {
  &.with-sidebar > .content {
    padding: $main-vertical-padding $main-horizontal-padding
      calc($main-vertical-padding * 2) $main-horizontal-padding;
  }

  &.without-sidebar > .content {
    padding: 0 0 3rem 0;
  }

  & > .content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 36px;

      .events-call-table-tabs {
        display: flex;
        align-items: center;
        list-style: none;

        li {
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            font-size: 16px;
            line-height: 1;
            color: $text-5;
            border-bottom: 4px solid transparent;
            text-decoration: none;
            padding: 0 0 12px;
            display: block;

            &:focus {
              @include outline();
            }
          }

          &.active,
          &:hover {
            a {
              border-color: $teal-dark;
              color: $text-3;
            }
          }
        }
      }
    }
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    .event-call-div {
      width: 50%;
      flex: 0 0 auto;
      margin: 0 12px 24px;

      @include responsive(1150px) {
        width: 100%;
        max-width: none;
        margin: 0 0 24px;
      }
    }
  }
}

.leaders-anchor {
  position: relative;
  top: -100px;
  visibility: hidden;
}

.weekly-digest-toggle-wrapper {
  display: flex;
  justify-content: flex-end;

  & > .input-component-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;

    p.body {
      margin-right: 16px;
    }
  }
}

.dashboard-events-call-meetings {
  .GojiCustomTable {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 400px;
        }
        &:nth-child(2) {
          width: 400px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 200px;
        }
      }
    }
  }

  .recurring-icon {
    position: absolute;
    top: 50%;
    left: -6px;
    transform: translateY(-50%);
  }
}

.users {
  .GojiCustomTable {
    tr {
      td,
      th {
        &:nth-child(1),
        &:nth-child(2) {
          width: 300px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          width: 100px;
        }
      }
    }
  }
}

.reports {
  .GojiCustomTable {
    tr {
      td,
      th {
        width: 300px;

        &.column-type-number {
          width: 100px;
        }
      }
    }
  }
}

.dashboard-community-import-subscribers {
  h2 {
    margin-bottom: 36px;
  }

  .section {
    margin-top: 36px;
  }

  .mindr-form {
    margin-top: 24px;
    padding-bottom: 0;

    .label {
      display: none;
    }
  }

  p {
    .link {
      display: inline-block;
    }

    + p {
      margin-top: 12px;
    }
  }

  &.csv_import_results .counters {
    margin-top: 24px;
  }
}

.csv_import_results {
  .section {
    overflow: hidden;
    margin: 0px 0px 30px 0px;
  }

  ul.counters {
    display: flex;
    align-items: center;

    li {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: $text-5;
        border-bottom: 4px solid transparent;
        text-decoration: none;
        padding: 10px 0;
        display: block;
        overflow: hidden;
        label {
          margin-right: 10px;
          display: inline-block;
          cursor: pointer;
        }

        &:focus {
          @include outline();
        }
      }

      &.active,
      &:hover {
        a {
          border-color: $teal;
          color: $text-3;
        }
      }
    }
  }

  .csv_import_action {
    display: inline-block;
    padding: 5px;
    border-radius: 2px;
    color: white;
    background-color: $text-9;
    border-color: $text-9;

    &.error {
      background-color: red;
      border-color: red;
    }
    &.modified {
      background-color: $teal;
      border-color: $teal;
    }
    &.added {
      background-color: $blue;
      border-color: $blue;
    }
    &.activated {
      background-color: $orange-dark;
      border-color: $orange-dark;
    }
    &.suspended {
      background-color: $violet;
      border-color: $violet;
    }
    &.skipped {
      background-color: $violet;
      border-color: $violet;
    }
    &.warning {
      background-color: $orange-dark;
      border-color: $orange-dark;
      color: $text-3;
    }
  }
}

.error-modal-container,
.splash-modal-container {
  padding: 124px 20px 40px;
  display: flex;
  justify-content: center;

  .error-modal,
  .splash-modal {
    background-color: #fff;
    width: 480px;
    padding: 64px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.error-modal {
  h1 {
    font-weight: 600;
    font-size: 80px;
    line-height: 120px;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 40px;
  }
}

.splash-modal {
  p {
    margin: 36px 0;
    text-align: center;
  }

  p + p {
    margin-top: 0;
  }
}

.post-survey-with-preview {
  .title-container {
    overflow: hidden;
    label {
      display: block;
      float: left;
    }
    .previews-container {
      float: left;
      padding-top: 2px;
      padding-left: 15px;
    }
  }
}

.add-participants-modal {
  form {
    margin-top: 12px;

    .label {
      color: $text-5;
    }
  }

  .user-picker .selectize-input {
    max-height: 170px;
    overflow: auto;
  }
}

.copy-auth-token-modal-body {
  .input-wrapper {
    display: flex;
    margin-top: 24px;
  }

  .copy-auth-token-field {
    margin: 0 12px 0 0;
  }
}

.dashboard-user-provisioning {
  .content {
    padding: 0 60px 60px;

    &:first-child {
      padding-top: 60px;
    }
  }

  .mindr-form {
    margin: 36px 0;
    padding-bottom: 0;

    section:last-child {
      padding-bottom: 0;
    }
  }

  .pgp-encryption {
    .button-container {
      display: flex;
      margin-top: 12px;
    }

    .input-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .link.pgp-key {
    svg path {
      stroke: $blue;
      fill: none;
    }

    &:hover svg path {
      stroke: $blue-dark;
    }
  }
}

.delete-modal-body {
  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import 'variables';
@import 'mixins';
@import 'flash';
@import 'modal';
@import 'buttons';
@import 'input';
@import 'rich-content';

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: $background-main;
  font-family: 'Lato', sans-serif;
  min-width: 1200px;
  &#mentorship_application {
    // min-width: unset;
    min-width: fit-content;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
}

h1 {
  @include title();
  font-size: 32px;
  line-height: 34px;
}

h2 {
  @include title();
  font-size: 24px;
  line-height: 30px;
}

h3 {
  @include title();
  font-size: 16px;
  line-height: 19px;
}

h5 {
  @include title();
  font-size: 13px;
  line-height: 22px;
  text-align: center;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: $text-5;

  &.caption {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $text-3;
  }

  &.bold {
    font-weight: 700;
  }
}

ul {
  padding: 0;
}

.caption {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: $text-5;

  &.bold {
    font-weight: 700;
  }
}

.section-title {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: $text-5;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
  }

  .header-left {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;

      &.call-header {
        width: 23px;
        height: 23px;
      }

      path {
        fill: $text-3;
      }

      &.stroke-icon path {
        fill: none;
        stroke: $text-3;
      }
    }

    .section-title {
      margin-right: 7px;
      color: $text-3;
    }

    p {
      line-height: 22px;
    }

    .link {
      margin-left: 8px;
      font-size: 14px;

      svg {
        height: 14px;
        width: 14px;
        margin-right: 4px;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .section-total {
    margin-right: 11px;
  }
}

.link {
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  color: $blue;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;

  &.full-name {
    margin-right: 7px;
  }

  & > span {
    max-width: calc(100% - 5px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg,
  img {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 7px;
    margin-top: 2px;
  }

  svg {
    fill: currentColor;
    path {
      fill: currentColor;
    }
  }

  a {
    color: currentColor;
  }

  &.small {
    font-size: 12px;
  }

  &:focus {
    @include outline();
  }

  &:hover {
    color: $blue-hover;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-label {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $teal-dark;
  text-transform: uppercase;
  text-decoration: none;

  &.small {
    padding: 5px 10px;
  }
}

header.container {
  background-color: #fff;
  padding: 5px $main-horizontal-padding;
  border-bottom: 1px solid $border-color;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  min-height: $header-height;
  height: $header-height;
  max-width: 100vw;

  .logo {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      @include outline;
    }

    img {
      display: block;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 1px solid $border-color;
      margin-right: 9px;
    }
  }

  .root-logo {
    display: block;

    img {
      height: 17px;
      display: block;
    }

    &:focus {
      @include outline;
      outline-color: #fff;
    }
  }

  .provided-by-logo {
    width: 226px;
    height: 17px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      opacity: 0.8;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 24px;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-decoration: none;
      color: $text-3;

      &:hover {
        opacity: 0.8;
        color: $text-5;
      }
    }
  }

  .link {
    display: flex;
    align-items: center;

    img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }

  .left {
    display: flex;
  }

  .org-switcher {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0 0 0 $grid-gap-basis;
    padding: 0;
    border-left: 1px solid $border-color;

    .org-item {
      margin-left: $grid-gap-basis;

      a {
        display: block;
        position: relative;
        height: 32px;

        span {
          display: inline-block;
          text-indent: -10000px;
        }

        &:hover::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          background-color: #000;
          opacity: 0.1;
          height: 32px;
          width: 32px;
          border-radius: 50%;
        }
      }

      img {
        margin: 0;
      }
    }
  }
}

.main {
  position: relative;
  margin-top: $header-height;
  min-height: calc(
    100vh - $header-height - $footer-height - ($grid-gap-basis * 3)
  );
  margin-bottom: calc(73px + ($grid-gap-basis * 4));

  &.without-footer {
    margin-bottom: 0;
  }

  &.with-sidebar {
    display: block;
    padding-left: $sidebar-width;
    transition: padding-left 0.5s ease-in;
    margin-bottom: $footer-height;

    &.collapsed {
      padding-left: $collapsed-sidebar-width;
    }
  }

  &.without-sidebar {
    display: block;
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

.container {
  margin: 0 auto;
  padding: 0 $main-horizontal-padding;
  max-width: 1440px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $footer-height;
  background-color: $background-grey;
  bottom: 0;
  position: fixed;
  z-index: 999;

  .footer-links {
    width: 50%;
    padding-right: 147px;
    display: flex;
    justify-content: flex-end;

    .footer-link {
      font-size: 13px;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  .footer-feedback {
    width: 50%;
    padding-left: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .feedback-text {
      display: flex;
      align-items: center;

      & svg {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      .text {
        font-weight: 700;
        font-size: 12px;
        line-height: 2;
        color: $text-3;
      }
    }

    .feedback-email {
      display: flex;
      align-items: center;
      margin-left: 13px;

      .email-label {
        color: $text-3;
        font-size: 13px;
        line-height: 1.38;
        margin-right: 3px;
      }

      .email-link {
        font-size: 13px;

        &:not(:last-child) {
          margin-right: 40px;
        }

        &:visited {
          color: $blue;
        }
      }
    }
  }
}

.navigation-tabs-wrapper {
  position: sticky;
  top: calc(#{$header-height} - 1px);
  z-index: 700;
  background-color: #fff;

  .navigation-tabs {
    display: flex;
    align-self: flex-end;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    cursor: pointer;
    padding-top: 20px;
    list-style: none;

    .tab {
      margin-right: 42px;

      a {
        transition: all 0.05s ease;
        border-bottom: 4px solid transparent;
        display: block;
        padding-bottom: 12px;
        text-decoration: none;
        color: $text-5;
        font-size: 16px;
        line-height: 1;

        &:hover,
        &:focus {
          color: $teal-dark;
        }

        &:focus {
          @include outline();
        }
      }

      &.active {
        a {
          color: $text-3;
          border-bottom-color: $teal-dark;
        }
      }
    }
  }
}

.switch-wrapper {
  display: flex;
  align-items: center;

  & > p {
    color: $text-5;
  }
  .switch-component-wrapper label:focus {
    @include outline();
  }
}

.section-title-wrapper {
  margin-bottom: 16px;
}

.tiles-switch {
  display: flex;
  align-items: center;

  .switch {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Lato', sans-serif;

    & svg {
      margin-right: 5px;
    }

    &:first-child {
      margin-right: 6px;
    }

    .label {
      color: $text-5;
      font-weight: 700;
    }

    &.teal {
      & svg {
        path {
          fill: $blue;
        }
      }

      .label {
        color: $blue;
      }
    }
  }
}

// shorthands
.w100 {
  width: 100%;
}

.mx-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.fw700 {
  font-weight: 700;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.error-message {
  color: $red;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.slick-track {
  margin: 0;
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  & > div,
  & > div .wrapper,
  & > div > div > div,
  & > div > div .event-call-div,
  & > div > div .community-card-div {
    height: 100%;
  }
}

body.disable-focus {
  * {
    &:focus {
      outline: none !important;
    }
  }
}

.selectize-input,
.selectize-dropdown-content {
  .cell-with-logo {
    padding: 3px 10px;
    display: flex;
    align-items: center;

    img,
    .default-user-avatar {
      float: left;
      height: 35px;
      min-width: 35px;
      line-height: 35px;
      border-radius: 50%;
      margin-right: 5px;
      color: #fff;
    }

    div:last-child {
      display: inline-block;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      color: $text-3;
      text-wrap: wrap;
    }
  }
}

.selectize-dropdown-content {
  .cell-with-logo:hover {
    background-color: $teal-light;
  }
}

.xdsoft_datetimepicker {
  z-index: 9999 !important;
}

.lozenge {
  display: inline-block;
  text-transform: none;
  color: #fff;
  background-color: $text-9;
  border-radius: 8px;
  line-height: 16px;
  font-size: 10px;
  font-weight: normal;
  text-align: center;

  &.lozenge-size-small {
    padding: 0 6px 1px;
    letter-spacing: 0.5px;
    line-height: 12px;
    font-size: 10px;
  }

  &.lozenge-size-large {
    padding: 0 12px;
    line-height: 16px;
    font-size: 12px;
  }

  &.lozenge-size-xlarge {
    padding: 0 12px;
    line-height: 19px;
    font-size: 13px;
  }

  &.completed {
    background-color: $blue;
  }

  &.error {
    background-color: $red;
  }

  &.in-progress {
    background-color: $orange-dark;
  }

  &.leader {
    background-color: $teal-light;
    color: $text-3;
  }
}

.pill {
  display: inline-block;
  color: $text-5;
  background-color: $background-grey;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-align: center;
  white-space: nowrap;
  border: 1px solid $background-grey;

  &.pill-with-border {
    border-color: rgba($text-5, 0.5);
  }

  &.pill-size-small {
    padding: $pill-small-padding-vertical 12px;
    font-size: $fs-pill-small;
    line-height: $fs-pill-small;
    border-radius: $pill-small-height;
    letter-spacing: 0.5px;
  }

  &.pill-size-large {
    padding: $pill-large-padding-vertical 12px;
    font-size: $fs-pill-large;
    line-height: $fs-pill-large;
    border-radius: $pill-large-height;
  }

  &.pill-text-transform {
    text-transform: uppercase;
  }

  &.pill-bold {
    font-weight: 600;
  }

  &.pill-color-blue {
    color: $blue;
    background-color: $blue-bg-light;
    border-color: $blue-bg-light;

    &.pill-with-border {
      border-color: rgba($blue, 0.5);
    }
  }

  &.pill-color-green {
    color: $green;
    background-color: $green-bg-light;
    border-color: $green-bg-light;

    &.pill-light {
      background-color: $green-bg-very-light;
      color: $text-3;
      border-color: $green-bg-very-light;
    }

    &.pill-dark {
      background-color: $teal-dark;
      color: $background-white;
      border-color: $teal-dark;
    }

    &.pill-with-border {
      border-color: rgba($green, 0.5);
    }
  }

  &.pill-color-purple {
    color: $purple-dark;
    background-color: $purple-light;
    border-color: $purple-light;

    &.pill-with-border {
      border-color: rgba($purple-dark, 0.5);
    }
  }

  &.pill-color-yellow {
    color: $yellow;
    background-color: $yellow-light;
    border-color: $yellow-light;

    &.pill-with-border {
      border-color: rgba($yellow, 0.5);
    }
  }

  &.pill-color-light-yellow {
    color: #756d23;
    background-color: #fffbd6;
    border-color: #fffbd6;

    &.pill-with-border {
      border-color: rgba(#756d23, 0.5);
    }
  }

  &.pill-color-red {
    color: $red;
    background-color: $red-light;
    border-color: $red-light;

    &.pill-with-border {
      border-color: rgba($red, 0.5);
    }
  }
}

.tab-marker {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-left: 4px;
  margin-bottom: 2px;
  background-color: $teal-dark;
}

.default-user-avatar {
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: $teal;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
}

.parent-container {
  &.flex {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
}

.child-container {
  &.flex {
    padding: 0 0 0 ($grid-gap-basis * 5);
    max-width: ($grid-gap-basis * 35);

    &.left {
      padding: 0 ($grid-gap-basis * 5) 0 0;
      border-right: 1px solid $border-color;
      min-width: 620px;
    }
  }
}

@import '../variables';

.welcome-wrapper {
  width: 100%;
  height: 100%;
  background: url('../images/login_bg.jpg'),
    linear-gradient(rgba($text-3, 0.45), rgba($text-3, 0.45));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.welcome {
  margin: 120px auto;
  padding: 60px 121px 43px;
  max-width: 622px;
  color: $text-3;
  background-color: #FFFFFF;
  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  & > svg {
    width: 260px;
    height: 37px;

    path {
      fill: $teal;
    }
  }

  h1 {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.67;
    color: $text-5;
    margin-bottom: 55px;
  }

  .welcome-header {
    margin-top: 30px;
  }

  .welcome-text {
    margin: 10px 0 30px;
    max-width: 305px;
    text-align: center;
    font-weight: 600;
  }

  .welcome-detais {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .welcome-interest {
    margin-bottom: 50px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    .interest-check {
      display: none;
      appearance: none;

      &:checked {
        & + .interest {
          background-color: $teal;

          .interest-description {
            color: #fff;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .interest {
      background: $teal-light;
      border-radius: 4px;
      box-shadow: $shadow;
      min-width: 178px;
      min-height: 178px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-out;
      color: $text-3;

      &:hover {
        background-color: $teal;
      }

      .interest-description {
        max-width: 140px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-top: 20px;
        text-align: center;
        user-select: none;
        color: $text-3;
      }
    }
  }

  
}

@import 'variables';
@import 'mixins';

.GojiCustomTable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-component-wrapper {
    margin: 0;
    width: 266px;
    &.switch-component-wrapper {
      width: auto;
    }
  }
}

.GojiCustomTable-description {
  margin-bottom: 32px;
}

.GojiCustomTable-wrapper {
  overflow: auto;
  box-shadow: $shadow;
  border-radius: 4px;

  &.subscriptions {
    thead tr th:last-child {
      min-width: 182px;
    }
  }
}

.GojiCustomTable {
  min-width: 100%;

  tr {
    td {
      font-size: 13px;
      line-height: 18px;
      color: $text-5;
      border-bottom: 1px solid $border-color;
      position: relative;

      &.column-type-html {
        position: relative;

        .pos-ab-on-hover {
          position: absolute;
          display: none;
          color: #555555;
          font-style: italic;
          font-size: 10px;
          line-height: 12px;
          top: 0;
          left: 0;
          right: 0;
          padding-left: 26px;
          padding-right: 26px;
        }
      }

      .GojiCustomTable__cell-value {
        padding: 8px 10px;
        display: block;
        color: inherit;
        text-decoration: none;

        & > a:not(.ignore-goji) {
          font-weight: inherit;
          font-style: inherit;
          font-size: inherit;
          font-family: inherit;
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .cell-with-logo {
          display: flex;
          align-items: center;

          img,
          .default-user-avatar {
            height: 42px;
            width: 42px;
            flex: 0 0 42px;
            line-height: 42px;
            border-radius: 50%;
            margin-right: 17px;
            color: #fff !important;
          }

          div {
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: $text-5;
          }

          .lozenge,
          .pill {
            margin-left: calc($grid-gap-basis / 2);
          }

          .user-suffix {
            font-weight: normal;
          }
        }
      }

      &:first-child {
        .GojiCustomTable__cell-value {
          &:first-child {
            padding-left: $grid-padding;
            padding-right: $grid-padding;
          }
        }
      }

      &:last-child {
        .GojiCustomTable__cell-value {
          &:last-child {
            padding-right: $grid-padding;
          }
        }
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  .checkbox-wrapper {
    text-align: center;
  }

  thead {
    tr {
      th,
      th button {
        font-weight: bold;
        font-size: 15px;
        font-family: 'Lato', sans-serif;
        line-height: 25px;
        color: $orange;
        text-align: left;

        &:hover {
          color: $orange-dark;
        }

        & > span {
          margin-left: 4px;
        }
      }

      th {
        padding: 5px 10px;
        background-color: #ffffff;
        border-bottom: 1px solid $border-color;

        button {
          border: none;
          white-space: nowrap;
          background-color: transparent;
          cursor: pointer;
          padding: 0;

          &:focus {
            @include outline();
          }
        }

        &:first-child {
          border-top-left-radius: 4px;
          padding-left: $grid-padding;
        }

        &:last-child {
          border-top-right-radius: 4px;
          padding-right: $grid-padding;
        }

        &.right-align {
          text-align: right;
        }
      }
    }
  }

  tbody,
  tfoot {
    tr {
      background-color: #fff;

      &.link-row {
        a:focus,
        button:focus {
          @include outline();
          outline-offset: -2px;
        }

        &:hover {
          td {
            background-color: $background-grey;

            .pos-ab-on-hover {
              display: block;
            }

            .cell-with-logo {
              div {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  tr .location {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-center {
    text-align: center;
  }

  &-pagination {
    display: flex;
    justify-content: center;
    margin-top: 28px;

    .pagination {
      display: flex;
      align-items: center;

      li {
        display: block;
        flex: 0 0 auto;

        a {
          padding: 0 4px;
          margin: 0 4px;
          font-size: 15px;
          line-height: 25px;
          cursor: pointer;
          outline: none;
          display: block;
          color: $text-5;

          &:focus {
            @include outline();
          }
        }

        &.active,
        &:hover {
          a {
            font-weight: 700;
          }
        }

        &.previous,
        &.next {
          margin: 0 0 0 6px;
          border-radius: 50%;

          &.disabled {
            opacity: 0.3;
          }

          a {
            height: 30px;
            width: 30px;
            margin: 0;
            padding: 0;
            background-size: 18px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../images/icons/chevron_right.svg');

            &:active {
              background-image: url('../images/icons/chevron_right_w.svg');
            }
          }

          &:hover,
          &:focus {
            background-color: $teal-light;
          }

          &:active {
            background-color: $teal;
          }
        }

        &.previous {
          margin: 0 6px 0 0;

          a {
            background-image: url('../images/icons/chevron_left.svg');

            &:active {
              background-image: url('../images/icons/chevron_left_w.svg');
            }
          }
        }
      }
    }
  }
}

@import '../variables';

.events-homepage {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: $footer-height;
}

.community-team-slider {
  .team-wrapper {
    height: 100%;
  }
}

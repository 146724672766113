@import 'variables';

.mindr-form {
  padding-bottom: 320px;

  .header {
    box-shadow: $shadow;
    padding: 17px $main-horizontal-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: $header-height;
    background-color: $background_main;
    z-index: 30;

    .required-mark {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-top: 3px;
      color: $text-5;
    }

    .buttons-container {
      display: flex;
      align-items: center;

      & > * {
        margin-left: 20px;
      }
    }
  }

  section {
    padding: 40px $main-horizontal-padding 0;

    &:last-child {
      padding-bottom: 40px;
    }

    .subtitle {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: $teal;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      & > * {
        margin-left: 8px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .section-description {
      margin-bottom: $main-bottom-padding;
      max-width: 900px;
      font-size: 15px;
      line-height: 25px;
      color: $text-3;

      &.timelines {
        margin-bottom: calc(#{$main-bottom-padding + 16px});
      }
    }

    .input-component-wrapper {
      max-width: $max-form-width;
    }

    .times {
      display: flex;
      max-width: $max-form-width;
      align-items: flex-start;
      margin-bottom: $main-bottom-padding;

      .input-component-wrapper {
        margin: 26px $grid-padding 0 0;
        position: relative;

        &:first-child {
          margin-top: 0;

          &:after {
            content: '–';
            display: block;
            position: absolute;
            top: 26px;
            right: -5px;
            transform: translateX(100%);
            line-height: 42px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .date-range {
      width: 100%;
    }

    .date-range-time-range-row {
      .input-component-wrapper {
        min-width: 0 !important;

        .selectize-input {
          display: block !important;
        }
      }

      .date-range-time-range {
        width: calc(66% - 5px);
        flex: 0 0 auto !important;
      }

      .timezone-wrapper {
        margin-top: 36px;
      }
    }

    .date-time-row {
      max-width: $max-form-width;
      display: flex;
      align-items: flex-start;

      .input-component-wrapper {
        margin-right: $grid-padding;

        &:nth-child(1) {
          flex: 1;
        }

        &:nth-child(2) {
          flex: 1;
          margin-right: 6px;
        }

        &:nth-child(3) {
          flex: 0 0 90px;
          margin-right: 0;
        }
      }

      .time-range {
        max-width: 220px;
      }

      .input-component-wrapper:last-child {
        margin-top: 37px;
      }
    }

    &.digest-send-fields {
      .digest-monthly {
        .digest-week-day label {
          visibility: hidden;
        }
      }

      .digest-weekly {
        .digest-month-week {
          display: none;
        }
      }

      .digest-off {
        display: none;
      }

      .date-time-row .input-component-wrapper {
        &:nth-child(1) {
          flex: 0 0 90px;
          margin-right: 6px;
        }

        &:nth-child(2) {
          flex: 1;
        }

        &:nth-child(3) {
          flex: 1;
          margin-right: 6px;
        }

        &:nth-child(4) {
          flex: 0 0 90px;
          margin-right: 0;
        }
      }
    }

    .date-row {
      margin-bottom: $main-bottom-padding;
      display: flex;
      gap: $grid-padding;

      .input-component-wrapper {
        max-width: 244px;
      }
    }

    .estimated-time-to-complete-row {
      .input-component-wrapper {
        .multiple-inputs {
          display: flex;
          align-items: flex-start;

          & > * {
            &:first-child {
              width: 80px;
              margin-right: 8px;
            }

            &:last-child {
              width: 100px;
            }
          }
        }
      }
    }

    .row {
      display: flex;
      max-width: $max-form-width;

      .input-component-wrapper {
        margin-right: $grid-padding;

        &:last-child {
          margin-right: 0;
        }

        &:not(.files-component-wrapper):not(.recurring) {
          min-width: 200px;
          flex: 1;
        }

        &.recurring {
          flex: 1;
          max-width: 200px;
        }

        &.files-component-wrapper {
          max-width: 110px;
          width: 110px;

          .image {
            margin-top: 12px;
          }
        }
      }

      &.communication-channel-row {
        .communication-channel-name-wrapper {
          max-width: 200px;
        }
      }

      &.location-type-row {
        max-width: 918px;

        .location-virtual {
          max-width: 354px;
        }

        .location-column {
          flex: 1 1;

          &:first-child {
            margin-right: $grid-padding;
            width: 126px;
            flex: 0 0 126px;
          }

          &:nth-child(3) {
            margin-right: 0;
            max-width: 312px;
          }

          &:last-child {
            margin-right: 0;
            max-width: 428px;
          }

          .location-details-input.hidden + .location-details-input {
            margin-right: 0;
          }

          .location-details-input + .location-details-input {
            margin-right: 26px;
          }
        }
      }

      &.recurring-fields {
        .input-component-wrapper {
          &:first-child {
            .input-wrapper {
              display: flex;

              & > * {
                flex: 1;
              }
            }
          }
        }
      }
    }

    .additional-section {
      margin-bottom: $main-bottom-padding;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      .section-header {
        svg {
          margin: 0;
          height: 24px;
          width: 24px;
        }
      }

      input[type='checkbox'] {
        height: 1px;
        width: 1px;
        top: -10px;
        left: -10px;
        position: absolute;
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        overflow: hidden;

        &:checked {
          & + .section-content {
            display: none;
          }

          & + .section-content + .show-button {
            display: block;
          }
        }

        & + .section-content {
          display: block;
        }

        & + .section-content + .show-button {
          display: none;
        }
      }
    }

    .additional-section-row {
      .input-component-wrapper {
        margin-bottom: 12px;
      }

      .delete {
        margin-top: 10px;
        height: 24px;
        align-self: flex-start;

        svg {
          font-size: 24px;
        }

        &.first {
          margin-top: 45px;
        }
      }

      &.first {
        align-items: flex-start;
        height: auto;

        .input-component-wrapper {
          .empty {
            margin-top: 10px;
          }

          &.vertical-checkbox {
            .label,
            .label::after {
              margin-top: -25px;
            }
          }
        }
      }

      .input-component-wrapper {
        .link {
          line-height: 42px;
        }
      }

      &:not(.first) {
        .input-component-wrapper {
          .chars-count {
            top: -4px;
          }
        }
      }

      &:last-of-type {
        .input-component-wrapper {
          margin-bottom: 16px;
        }
      }
    }

    .show-button {
      max-width: $max-form-width;
      text-align: left;
    }
  }

  div[data-react-class] {
    > section {
      padding-bottom: 0;
    }

    &:last-child > section {
      padding-bottom: 40px;
    }
  }

  &.profile {
    padding-bottom: 40px;
    width: 700px;
    margin: 0 auto;

    .header {
      position: static;
      box-shadow: none;

      .title {
        .org-avatar {
          border-radius: 50%;
          height: 60px;
          width: 60px;
          display: block;
          margin-bottom: 16px;
        }
      }
    }

    section {
      padding-top: 0;
    }

    .buttons-container {
      .button {
        margin: 0 auto;
      }
    }
  }

  .content {
    padding: $main-vertical-padding $main-horizontal-padding
      calc($main-vertical-padding * 2) $main-horizontal-padding;
  }
}

.buttons-container .mindr-form {
  padding-bottom: 0;
}
